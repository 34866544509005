import React from "react";
import BreadcrumbNodes from "../../BreadcrumbNodes/BreadcrumbNodes";
import EmptyData from "../../EmptyData/EmptyData";
import UploadButton from "../../UploadButton/UploadButton";
import { setOpenAddFolderModal } from "../../../store/features/CustomerDetails.slice";
import { DetailsWrapper } from "../../../pages/ProductDetail/ProductDetailStyled";
import CustomerFolderTree from "../CustomerFolderTree/CustomerFolderTree";
import CustomerSpecificFiles from "../CustomerSpecificFiles/CustomerSpecificFiles";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  _checkActionPermission,
  checkAssignedRole,
  isRestrictedFolder,
} from "../../../utils/utils";
import {
  RESTRICTED_FOLDERS,
  USER_ROLES,
  VIEWER_ACCESS_FOLDERS,
} from "../../../utils/constants/common";
import { FOLDER_MSG } from "../../../utils/constants/messages";

const EMPTY_MSG: {
  [key: string]: string;
} = {
  documentation: "documentation file",
  designs: "design file",
  "test data": "test data file",
};

const CustomerSpecificTreeWrapper = ({
  isIndividual,
}: {
  isIndividual: boolean;
}) => {
  let hasPermission: boolean = false;

  const dispatch = useAppDispatch();
  const { breadcrumbs, document_loader, customer_active_node } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const { customer_specific_files } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const emptyDataMsg = EMPTY_MSG[customer_active_node?.name?.toLowerCase()];
  const assignedRole = !isIndividual && checkAssignedRole();
  const hasNofileAndFolder =
    customer_active_node?.no_of_documents === 0 &&
    customer_active_node?.no_of_sub_nodes === 0;

  const _checkCustomerUserPermission = () => {
    if (isRestrictedFolder(customer_active_node?.name)) return true;
    if (
      customer_active_node?.path &&
      !Array.isArray(customer_active_node?.path)
    ) {
      const pathValues: string[] = Object.values(customer_active_node?.path);
      return RESTRICTED_FOLDERS.includes(pathValues[0]?.toLowerCase());
    }
    return false;
  };

  const _checkViewerPermission = () => {
    if (
      VIEWER_ACCESS_FOLDERS.includes(customer_active_node?.name?.toLowerCase())
    )
      return true;
    if (
      customer_active_node?.path &&
      !Array.isArray(customer_active_node?.path)
    ) {
      const pathValues: string[] = Object.values(customer_active_node?.path);
      return VIEWER_ACCESS_FOLDERS.includes(pathValues[0]?.toLowerCase());
    }
    return false;
  };
  hasPermission = isIndividual
    ? _checkCustomerUserPermission()
    : assignedRole === USER_ROLES.VIEWER
    ? _checkViewerPermission()
    : _checkActionPermission(assignedRole, customer_active_node?.name, true);

  return (
    <DetailsWrapper>
      <div className="tree-section">
        <CustomerFolderTree />
      </div>
      <div className="file-section">
        {breadcrumbs?.length > 0 && !document_loader && <BreadcrumbNodes />}
        {customer_specific_files?.length ? (
          <>
            <CustomerSpecificFiles hasPermission={hasPermission} />
          </>
        ) : (
          !document_loader && (
            <>
              {hasPermission && (
                <div className="action-btn">
                  <UploadButton
                    addFolder={() => dispatch(setOpenAddFolderModal(true))}
                  />
                </div>
              )}
              <EmptyData
                customMessage={
                  !isIndividual
                    ? `No ${emptyDataMsg || "file"}s uploaded yet.`
                    : hasNofileAndFolder
                    ? FOLDER_MSG.NO_FILE_OR_FOLDER
                    : FOLDER_MSG.NO_FILES
                }
                type={emptyDataMsg}
                hideMessage={true}
              />
            </>
          )
        )}
      </div>
    </DetailsWrapper>
  );
};

export default CustomerSpecificTreeWrapper;
